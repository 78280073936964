import i18next from 'i18next'
import { it } from './locales/it'
import { en } from './locales/en'

i18next.init({
  lng: document.documentElement.lang,
  debug: window.appData.env == 'development',
  resources: {
    it,
    en,
  }
})
