export const role_permissions_content = {
  actions: 'Actions',
  permission_group: {
    organization: 'Global permissions',
    project_global: 'Permissions for all construction sites',
    project_specific: 'Permissions for this construction site',
  },
  reset: 'Reset',
  resource: 'Resource',
  role: 'Role',
  select_role: 'Select role',
  delete_role: 'Delete role',
  no_role: 'No permissions for this role.',
}
