import { Controller } from '@hotwired/stimulus'

// This controller is used to add a class to an element if it has never been clicked
// Eg.
// - a bell icon that opens a changelog dialog
// - any "don't show again" dialog

// Params:
// - `key` is the key in localStorage, can be eg. "changelog:1.1.2" or just "some-key"
// - `trueClass` is the class to add to the element if the key is not in localStorage

// Connects to data-controller="utils--show-no-more"
export default class extends Controller {
  static values = {
    key: { type: String, default: 'showNoMore' },
    trueClasses: String,
  }

  get parsedKey() {
    const [storageKey, value = 'true'] = this.keyValue.split(':', 2)
    return { storageKey: `showNoMore-${storageKey}`, value }
  }

  connect() {
    const { storageKey, value } = this.parsedKey
    const storedValue = localStorage.getItem(storageKey)

    if (value !== storedValue && this.trueClassesValue) {
      this.trueClassesValue.split(' ').forEach(className => this.element.classList.add(className))
    }
  }

  showNoMore() {
    const { storageKey, value } = this.parsedKey
    localStorage.setItem(storageKey, value)

    if (this.trueClassesValue) {
      this.trueClassesValue.split(' ').forEach(className => this.element.classList.remove(className))
    }
  }
}
