import { export_templates } from './export_templates/en'
import { draw } from './draw/en'
import { form } from './form/en'
import { gantt } from './gantt/en'
import { manual_measurements } from './manual_measurements/en'
import { pages } from './pages/en'
import { project_contacts } from './project_contacts/en'
import { sites } from './sites/en'

export const views = {
  ...export_templates,
  ...draw,
  ...form,
  ...gantt,
  ...manual_measurements,
  pages,
  ...project_contacts,
  ...sites,
}
